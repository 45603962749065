import React, { useState } from "react"

import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"
import {
  MyAccountDetails,
  MyAccountOrders,
  MyAccountAddresses,
} from "components/MyAccount"
import LazyPlaceholder from "components/LazyPlaceholder/LazyPlaceholder"

import { useCustomer } from "context/customer"

const MyAccount = ({ title, handleLogout }) => {
  const [tab, setTab] = useState(1)
  const { loading, customer, error } = useCustomer()

  if (loading) return <LazyPlaceholder />
  if (error) return <p>{error.message}</p>

  return (
    <>
      <PageHeader
        title={`${title} - ${customer.firstName} ${customer.lastName}`}
      />
      <div className="row">
        <div className="col-lg-3">
          <PageSubHeader title="Panel Klienta" />
          <div className="account__nav">
            <button
              className={tab === 1 ? "current" : ""}
              onClick={() => setTab(1)}
            >
              Szczegóły konta
            </button>
            <button
              className={tab === 2 ? "current" : ""}
              onClick={() => setTab(2)}
            >
              Zamówienia
            </button>
            <button
              className={tab === 3 ? "current" : ""}
              onClick={() => setTab(3)}
            >
              Adresy
            </button>
          </div>
          <Button onClick={handleLogout}>Wyloguj</Button>
        </div>
        <div className="col-lg-8 offset-lg-1">
          {tab === 1 ? (
            <MyAccountDetails />
          ) : tab === 2 ? (
            <MyAccountOrders />
          ) : tab === 3 ? (
            <MyAccountAddresses />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  )
}

export default MyAccount
