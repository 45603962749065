import React from "react"
import { v4 } from "uuid"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { useMutation } from "@apollo/client"

import Input from "components/Input/Input"
import Select from "components/Select/Select"
import Button from "components/Button/Button"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import { useCustomer } from "context/customer"
import UPDATE_CUSTOMER_BILLING from "mutations/update-customer-billing"
import validation_schema from "validator/address"

const BillingAddress = () => {
  const { refetch, customer } = useCustomer()
  const {
    billing: { __typename, ...initialValues },
  } = customer

  const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER_BILLING, {
    onCompleted: async () => {
      await refetch()
      toast.success("Zaktualizowano adres")
    },
    onError: error => {
      if (error) {
        toast.error("Wystąpił błąd")
        console.warn(error)
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validation_schema,
    onSubmit: data => {
      updateCustomer({
        variables: {
          input: {
            clientMutationId: v4(),
            billing: {
              ...data,
            },
          },
        },
      })
    },
  })

  return (
    <>
      <PageSubHeader title="Edytuj adres rozliczeniowy" />
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <Input
              placeholder="Imię"
              type="text"
              id="firstName"
              name="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeholder="Nazwisko"
              type="text"
              id="lastName"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
          </div>
          <div className="col-md-6">
            <Select
              placeholder="Kraj / Region"
              id="subject"
              onChange={formik.handleChange}
              value={formik.values.country}
              options={[{ label: "Polska", value: "PL" }]}
              // options={formCountries.map(({ code: value, value: label }) => ({
              //   label,
              //   value,
              // }))}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeholder="Ulica"
              type="text"
              id="address1"
              name="address1"
              onChange={formik.handleChange}
              value={formik.values.address1}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeholder="Numer domu / mieszkania"
              type="address2"
              id="address2"
              name="address2"
              onChange={formik.handleChange}
              value={formik.values.address2}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeholder="Kod pocztowy"
              type="postcode"
              id="postcode"
              name="postcode"
              onChange={formik.handleChange}
              value={formik.values.postcode}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeholder="Miasto"
              type="city"
              id="city"
              name="city"
              onChange={formik.handleChange}
              value={formik.values.city}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeholder="Telefon"
              type="phone"
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
          </div>
        </div>
        <Button type="submit" disabled={loading || !formik.isValid}>
          {loading ? "Zapisywanie..." : "Zapisz"}
        </Button>
      </form>
    </>
  )
}

export default BillingAddress
