import { gql } from "@apollo/client"

const UPDATE_CUSTOMER = gql`
  mutation UpdateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $id: ID!
  ) {
    updateUser(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
      }
    ) {
      user {
        firstName
        lastName
        email
      }
    }
  }
`

export default UPDATE_CUSTOMER
