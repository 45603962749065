import "styles/pages/account.scss"

import React from "react"
import { navigate } from "gatsby"
import { toast } from "react-toastify"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import { MyAccount } from "components/MyAccount"
import { isUserLoggedIn, logOut } from "utils/functions"
import CustomerProvider from "context/customer"

const Account = () => {
  const title = "Moje konto"

  const auth = isUserLoggedIn()
  const isBrowser = typeof window !== "undefined"

  const handleLogout = () => {
    logOut()
    toast.success("Poprawnie wylogowano!")
    navigate("/")
  }

  if (!auth && isBrowser) {
    navigate("/logowanie/")
    toast.warning("Użytkownik nie zalogowany.")
    return null
  }

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />
      <CustomerProvider>
        <section className="account">
          <div className="container-fluid">
            <MyAccount title={title} handleLogout={handleLogout} />
          </div>
        </section>
      </CustomerProvider>
    </Layout>
  )
}

export default Account
