import { gql } from "@apollo/client"

const UPDATE_CUSTOMER_SHIPPING = gql`
  mutation UpdateCustomerShipping($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        shipping {
          address1
          address2
          city
          company
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
      }
    }
  }
`

export default UPDATE_CUSTOMER_SHIPPING
