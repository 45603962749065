import React, { useState } from "react"

import Input from "components/Input/Input"
import Button from "components/Button/Button"

const MyAccountDetailsFormPass = () => {
  const [currentPass, setCurrentPass] = useState("")
  const [newPass, setNewPass] = useState("")
  const [newPassRepeat, setNewPassRepeat] = useState("")

  return (
    <form>
      <div className="row">
        <div className="col-md-12">
          <Input
            placeholder="Aktualne hasło"
            type="password"
            id="currentPass"
            name="currentPass"
            value={currentPass}
            onChange={e => setCurrentPass(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <Input
            placeholder="Nowe hasło"
            type="password"
            id="newPass"
            name="newPass"
            value={newPass}
            onChange={e => setNewPass(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <Input
            placeholder="Powtórz nowe hasło"
            type="password"
            id="newPassRepeat"
            name="newPassRepeat"
            value={newPassRepeat}
            onChange={e => setNewPassRepeat(e.target.value)}
          />
        </div>
      </div>
      <Button type="submit">Zmień</Button>
    </form>
  )
}

export default MyAccountDetailsFormPass
