import { gql } from "@apollo/client"

const GET_CUSTOMER = gql`
  query GET_CUSTOMER {
    customer {
      id
      firstName
      lastName
      username
      email
      billing {
        address1
        address2
        city
        company
        email
        country
        firstName
        phone
        lastName
        postcode
        state
      }
      shipping {
        address1
        address2
        city
        company
        country
        email
        firstName
        lastName
        phone
        postcode
        state
      }
      orders {
        nodes {
          id
          orderNumber
          orderKey
          date
          total
          status
        }
      }
    }
  }
`

export default GET_CUSTOMER
