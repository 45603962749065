import React from "react"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import { useCustomer } from "context/customer"
import { BillingAddress } from "./components"

const MyAccountAddresses = () => {
  const {
    customer: { billing },
  } = useCustomer()

  return (
    <div className="account__addresses">
      <PageSubHeader title="Adresy" />
      <p>Następujący adres zostanie użyty domyślnie na stronie zamówienia.</p>

      <PageSubHeader title="Dane" />
      <div className="account__info-list">
        <p>
          <span>Imię i nazwisko:</span> {billing?.firstName} {billing?.lastName}
        </p>
        <p>
          <span>Ulica:</span> {billing?.address1} {billing?.address2}
        </p>
        <p>
          <span>Miasto:</span> {billing?.postcode} {billing?.city}
        </p>
        <p>
          <span>Telefon:</span> {billing?.phone}
        </p>
        <p>
          <span>E-mail:</span> {billing?.email}
        </p>
      </div>
      <BillingAddress />
    </div>
  )
}

export default MyAccountAddresses
