import React from "react"
import { v4 } from "uuid"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { useMutation } from "@apollo/client"

import Input from "components/Input/Input"
import Button from "components/Button/Button"
import { useCustomer } from "context/customer"
import UPDATE_CUSTOMER from "mutations/update-customer"
import validation_schema from "validator/user"

const MyAccountDetailsFormData = () => {
  const { refetch, customer } = useCustomer()

  const { __typename, billing, shipping, orders, username, ...initialValues } =
    customer

  const [updateUser, { loading }] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: async () => {
      await refetch()
      toast.success("Zaktualizowano dane użytkownika")
    },
    onError: error => {
      if (error) {
        toast.error("Wystąpił błąd")
        console.warn(error)
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validation_schema,
    onSubmit: data => {
      updateUser({
        variables: {
          clientMutationId: v4(),
          ...data,
        },
      })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <Input
            placeholder="Imię"
            type="text"
            id="firstName"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
        </div>
        <div className="col-md-6">
          <Input
            placeholder="Nazwisko"
            type="text"
            id="lastName"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
        </div>
      </div>
      <Button type="submit" disabled={loading || !formik.isValid}>
        {loading ? "Aktualizowanie..." : "Aktualizuj"}
      </Button>
    </form>
  )
}

export default MyAccountDetailsFormData
