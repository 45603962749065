import "./select.scss"

import React from "react"
import classnames from "classnames"

const mainClass = "select-wrapper"

const Select = ({
  name,
  id,
  className,
  options,
  placeholder,
  onChange,
  value = "",
}) => {
  return (
    <div className={classnames(mainClass, className)}>
      <select
        name={name}
        id={id}
        value={value}
        className={`${mainClass}__select`}
        onChange={onChange}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options &&
          options.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            )
          })}
      </select>
    </div>
  )
}

export default Select
