import React from "react"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"
import { useCustomer } from "context/customer"

const MyAccountOrders = () => {
  const { customer } = useCustomer()
  return (
    <div className="account__orders">
      <PageSubHeader title="Zamówienia" />

      {customer.orders.nodes.length > 0 ? (
        <div className="account__table">
          <div className="account__table-header">
            <div className="row">
              <div className="col-md-2">
                <small>Numer zamówienia</small>
              </div>
              <div className="col-md-2">
                <small>Data złożenia</small>
              </div>
              <div className="col-md-2">
                <small>Status</small>
              </div>
              <div className="col-md-2">
                <small>Wartość zamówienia</small>
              </div>
              <div className="col-md"></div>
            </div>
          </div>

          {customer.orders.nodes.length > 0 &&
            customer.orders.nodes.map(
              ({ orderNumber, date, total, status, id }) => (
                <div className="account__table-item" key={id}>
                  <div className="row align-items-center">
                    <div className="col-md-2">
                      <small className="account__table-mobile-label">
                        Numer zamówienia
                      </small>
                      #{orderNumber}
                    </div>
                    <div className="col-md-2">
                      <small className="account__table-mobile-label">
                        Data złożenia
                      </small>
                      {date?.split("T")?.[0]?.split("-").reverse().join(".")}
                    </div>
                    <div className="col-md-2">
                      <small className="account__table-mobile-label">
                        Status
                      </small>
                      {status}
                    </div>
                    <div className="col-md-2">
                      <small className="account__table-mobile-label">
                        Wartość zamówienia
                      </small>
                      {total}
                    </div>
                    <div className="col-md text-right">
                      <Button
                        type="link"
                        to={`/moje-konto/zamowienie?order_id=${id}`}
                      >
                        Szczegóły
                      </Button>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      ) : (
        <p>Brak zamówień.</p>
      )}
    </div>
  )
}

export default MyAccountOrders
