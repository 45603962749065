import React, { createContext, useMemo, useContext } from "react"
import { useQuery } from "@apollo/client"
import GET_CUSTOMER from "queries/get-customer"
//IMPORT REDUCER, INITIAL STATE AND ACTION TYPES

// CONTEXT ===================================
const CustomerContext = createContext()

function CustomerProvider(props) {
  // const authToken = localStorage.getItem('authToken')
  const { refetch, loading, error, data } = useQuery(GET_CUSTOMER)
  const value = useMemo(() => {
    return {
      loading,
      error,
      refetch,
      ...data,
    }
  }, [refetch, loading, error, data])

  return (
    <CustomerContext.Provider value={value}>
      {props.children}
    </CustomerContext.Provider>
  )
}

const useCustomer = () => useContext(CustomerContext)
export { CustomerContext, useCustomer }
export default CustomerProvider
