import React from "react"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import {
  MyAccountDetailsFormData,
  MyAccountDetailsFormPass,
} from "./components"
import { useCustomer } from "context/customer"
const MyAccountDetails = () => {
  const { customer } = useCustomer()
  return (
    <div className="account__details">
      <PageSubHeader title="Szczegóły konta" />
      <div className="account__info-list">
        <p>
          <span>Imię i nazwisko:</span> {customer.firstName} {customer.lastName}
        </p>
        <p>
          <span>Login użytkownika:</span> {customer.username}
        </p>
        <p>
          <span>E-mail:</span> {customer.email}
        </p>
      </div>

      <PageSubHeader title="Edytuj dane" />
      <MyAccountDetailsFormData />

      <PageSubHeader title="Zmiana hasła" />
      <MyAccountDetailsFormPass />
    </div>
  )
}

export default MyAccountDetails
